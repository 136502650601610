export const IOEvents = {
    CONNECT: "CONNECT",
    DISCONNECT: "disconnect",
    SET_LANGUAGE: "SET_LANGUAGE",
    AUTHORIZATION: "AUTHORIZATION",
    INVALID_PARTICIPANT: "INVALID_PARTICIPANT",
    ALREADY_JOINED: "ALREADY_JOINED",
    CREATE_ROOM: "CREATE_ROOM",
    ROOM_JOIN: "ROOM_JOIN",
    ROOM_EXIST: "ROOM_EXIST",
    ROOM_NOT_FOUND: "ROOM_NOT_FOUND",
    JOINED_ROOM_AS_RECEIVER: "JOINED_ROOM_AS_RECEIVER",
    CALL_ON_WAIT: "CALL_ON_WAIT",
    START_CALL: "START_CALL",
    ANSWER_CALL: "ANSWER_CALL",
    END_CALL: "END_CALL",
    RECEIVE_ANSWER: "RECEIVE_ANSWER",
    CREATE_ICE_EVENT_DATA: "CREATE_ICE_EVENT_DATA",
    MEETING_NOT_FOUND: "MEETING_NOT_FOUND",
    MEETING_NOT_ACTIVE: "MEETING_NOT_ACTIVE",
    MUTE_VIDEO: "MUTE_VIDEO",
    UNMUTE_VIDEO: "UNMUTE_VIDEO",
    MUTE_AUDIO: "MUTE_AUDIO",
    UNMUTE_AUDIO: "UNMUTE_AUDIO",
    SCREEN_SHARING_ENABLED: "SCREEN_SHARING_ENABLED",
    SCREEN_SHARING_DISABLED: "SCREEN_SHARING_DISABLED",
    OPEN_BOARD: "OPEN_BOARD",
    CLOSE_BOARD: "CLOSE_BOARD",
    NEW_OFFER: "NEW_OFFER",
    NEW_ANSWER: "NEW_ANSWER",
    RECONNECTING: "RECONNECTING"
};
